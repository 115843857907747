import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Contact = () => {
  const lang = "en"

  return (
    <Layout lang={lang} translation="/pl/kontakt/" contactFooter>
      <SEO title="Contact" />
    </Layout>
  )
}

export default Contact
